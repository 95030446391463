/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { firestore } from "../../firebase";
import "./home.css";
import "../../index.scss";
import 'animate.css/animate.css'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  onSnapshot,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { UserContext } from "../../providers/UserProvider";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  Tooltip,
  Title,
  Filler,
  ArcElement,
  BarElement
} from 'chart.js';
import { LoaderFetch } from "../../loader";
import { StatsContext } from "../../providers/StatsProvider";
import EventBox from "../widgets/eventbox";
import {
  viewsIcon,
  followersIcon,
  sharesIcon,
  plusIcon,
  chevronLeftIcon,
  chevronRightIcon,
  warningIcon,
  bookmarksIcon,
  ticketIcon,
} from "../../icons.jsx";
import LoaderDivWithOverlay from "../widgets/loaderdiv.jsx";
import PieChart from "../widgets/statistics_widgets/pieChart";
import FurtherInfo from "../widgets/tooltip";
import BarChart from "../widgets/statistics_widgets/barChart";
import LineGraph from "../widgets/statistics_widgets/lineGraph";
import { toast } from "react-toastify";
import ModalChangelog from "./special/changelog.jsx";
import { OrganizerContext } from "../../providers/OrganizerProvider.jsx";

let handle;

function Home() {
  const navigate = useNavigate();
  const { organizerId,isAdmin } = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const [views, setViews] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const [paymentsDoc, setPaymentsDoc] = useState(null);
  useEffect(() => { onSnapshot(doc(collection(doc(collection(firestore, 'organizers'), organizerId), 'private'), 'payments'), e => setPaymentsDoc(e.exists() ? e : null)) }, [organizerId]);

  
  useEffect(() => {
    getDocs(
      query(
        collection(firestore, "events"),
        where(
          "start",
          ">",
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        where(
          "start",
          "<",
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
        ),
        where("organizer", "array-contains", organizerId),
      )
    ).then((data) => {
      setViews(
        data.docs.reduce((prev, curr) => prev + curr.data().view_count ?? 0, 0)
      );
    });
  }, [organizerId]);
  const [followers, setFollowers] = useState(0);
  const [ticketSales, setTicketSales] = useState(0);
  const [viewsOrga, setViewsOrga] = useState(0);
  const [sharesOrga, setSharesOrga] = useState(0);
  const [ticketSalesState, setTicketSalesState] = useState(2)
  // eslint-disable-next-line no-unused-vars
  const [ticketingLoading, setTicketingLoading] = useState(true)
  const [statisticsMenuState, setStatisticsMenuState] = useState(0)
  const { loaded: statsLoaded, getTicketSales } = useContext(StatsContext);
  const [ticketSalesWeek, ticketSalesWeekSums, ticketSalesWeekNum, ticketSalesWeekLabels] = getTicketSales({ timeframe: 'week' });
  const [ticketSalesToday, ticketSalesTodaySums, ticketSalesTodayNum, ticketSalesTodayLabels] = getTicketSales({ timeframe: 'today' });
  const [ticketSalesYesterday, ticketSalesYesterdaySums, ticketSalesYesterdayNum, ticketSalesYesterdayLabels] = getTicketSales({ timeframe: 'yesterday' });
  const [ticketSalesOverall, ticketSalesOverallSums, ticketSalesOverallNum, ticketSalesOverallLabels] = getTicketSales({ timeframe: 'overall' });
  const [ticketSalesActive, setTicketSalesActive] = useState([])
  const [ticketSalesActiveSums, setTicketSalesActiveSums] = useState([])
  const [ticketSalesActiveNum, setTicketSalesActiveNum] = useState(0)
  const [ticketSalesActiveLabels, setTicketSalesActiveLabels] = useState([])
  const [ticketSalesActiveText, setTicketSalesActiveText] = useState("Heute")
  const [showLog, setShowLog] = useState(false);
  const handleCloseLog = () => setShowLog(false);
  // eslint-disable-next-line no-unused-vars
  const [ticketSalesActiveArraySum, setTicketSalesActiveArraySum] = useState(0)


  //Use setTimeframe to set timeframe for all statistics
  // eslint-disable-next-line no-unused-vars
  const [timeframe, setTimeframe] = useState('last_30_days');
  const {
    getCustomerStatuses,
    getCustomerAges,
    getCustomerGenders,
  } = useContext(StatsContext);

  //Kundenstamm
  // eslint-disable-next-line no-unused-vars
  const [customerStatuses, customerStatusesLabels] = getCustomerStatuses({ timeframe });
  const [customerStatusesSum, setCustomerStatusesSum] = useState(0)

  //Genders
  // eslint-disable-next-line no-unused-vars
  const [customerGenders, customerGendersLabels] = getCustomerGenders({ timeframe })
  const [customerGendersSum, setCustomerGendersSum] = useState(0)

  //Ages
  // eslint-disable-next-line no-unused-vars
  const [customerAges, customerAgesLabels] = getCustomerAges({ timeframe });
  const [customerAgesSum, setCustomerAgesSum] = useState(0)


  useEffect(() => {
    // Get Sums for Statuses
    let sumStauses = 0;
    for (let index = 0; index < customerStatuses.length; index++) {
      sumStauses += customerStatuses[index]
    }
    setCustomerStatusesSum(sumStauses)

    // Get Sums for Genders
    let sumGenders = 0;
    for (let index = 0; index < customerGenders.length; index++) {
      sumGenders += customerGenders[index]
    }
    setCustomerGendersSum(sumGenders)

    // Get Sums for Ages
    let sumAges = 0;
    for (let index = 0; index < customerAges.length; index++) {
      sumAges += customerAges[index]
    }
    setCustomerAgesSum(sumAges)
  }, [])


  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    ArcElement,
    BarElement,
    Filler)


  useEffect(() => {
    if (ticketSalesState === 0) {
      setTicketSalesActive(ticketSalesToday)
      setTicketSalesActiveSums(ticketSalesTodaySums)
      setTicketSalesActiveNum(ticketSalesTodayNum)
      setTicketSalesActiveLabels(ticketSalesTodayLabels)
      setTicketSalesActiveText("Heute")
    }
    else if (ticketSalesState === 1) {
      setTicketSalesActive(ticketSalesYesterday)
      setTicketSalesActiveSums(ticketSalesYesterdaySums)
      setTicketSalesActiveNum(ticketSalesYesterdayNum)
      setTicketSalesActiveLabels(ticketSalesYesterdayLabels)
      setTicketSalesActiveText("Gestern")
    }
    else if (ticketSalesState === 2) {
      setTicketSalesActive(ticketSalesWeek)
      setTicketSalesActiveSums(ticketSalesWeekSums)
      setTicketSalesActiveNum(ticketSalesWeekNum)
      setTicketSalesActiveLabels(ticketSalesWeekLabels)
      setTicketSalesActiveText("Woche")
    }
    else if (ticketSalesState === 3) {
      setTicketSalesActive(ticketSalesOverall)
      setTicketSalesActiveSums(ticketSalesOverallSums)
      setTicketSalesActiveNum(ticketSalesOverallNum)
      setTicketSalesActiveLabels(ticketSalesOverallLabels)
      setTicketSalesActiveText("Gesamt")
    }
  }, [ticketSalesState, statsLoaded])

  const [activities, setActivities] = useState(null)
  const newInvoiceToast = () => toast.info("Du hast neue Rechungen! Du findest diese in den Settings.", { theme: "dark" })
  useEffect(() => {
    const fetchOrganizerData = async () => {
      try {
        const snapshot = await getDoc(doc(collection(firestore, "organizers"), organizerId));
  
        if (snapshot.exists()) {
          const organizerData = snapshot.data();
  
          // Set followers and loading state
          setFollowers(organizerData.subscription_count ?? 0);
          setTicketingLoading(false);
  
          // Handle new invoices
          if (organizerData.has_new_invoices) {
            newInvoiceToast();
            updateDoc(doc(collection(firestore, "organizers"), organizerId), { "has_new_invoices": false });
          }
  
          // Check if there's a last visited changelog
          if (organizerData.last_visited_changelog && !isAdmin) {
            // Fetch the changelogs
            const querySnapshot = await getDocs(collection(firestore, "changelogs", "dashboard", "changes"));
            const sortedChangelogs = querySnapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data()
              }))
              .sort((a, b) => b.created_timestamp.seconds - a.created_timestamp.seconds); // Sort by most recent first
  
            const lastVisitedIndex = sortedChangelogs.findIndex(changelog => changelog.id === organizerData.last_visited_changelog);
  
            // If there are more recent changelogs, set showLog to true
            if (lastVisitedIndex > 0 && !isAdmin) {
              setShowLog(true);
            } else {
              setShowLog(false);
            }
          } else {
            if(isAdmin) return
            // If there's no last visited changelog, this means all changelogs are new
            setShowLog(true);
          }
        }
      } catch (error) {
        console.error('Error fetching organizer data:', error);
      }
    };
  
    fetchOrganizerData();
    fetchActivities().catch((error) => console.log(error));
  
    return () => {
      clearInterval(handle);
    };
  }, [organizerId,isAdmin]);
  

  useEffect(() => {
    getDocs(query(
      collection(firestore, 'activities'),
      where('timestamp', '>', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)),
      where('organizer', '==', organizerId)
    )).then(e => {
      setViewsOrga(e.docs.filter(e => e.get('type') === 'organizer_view').length);
    })
  }, [organizerId]);

  useEffect(() => {
    getDocs(query(
      collection(firestore, 'activities'),
      where('timestamp', '>', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)),
      where('organizer', 'array-contains', organizerId)
    )).then(e => {
      setTicketSales(e.docs.filter(e => e.get('type') === 'event_view').length);
      setSharesOrga(e.docs.filter(e => e.get('type') === 'event_share').length);
    })
  }, [organizerId]);
  useEffect(() => {
    let tmp = 0;
    ticketSalesActive.forEach(item => {
      tmp += item
    })
    setTicketSalesActiveArraySum(tmp)
  }, [ticketSalesActive])


  function timeAgo(date) {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return "vor " + years + " Jahr" + (years > 1 ? "en" : "");
    } else if (days > 0) {
      return "vor " + days + " Tag" + (days > 1 ? "en" : "");
    } else if (hours > 0) {
      return "vor " + hours + " Stunde" + (hours > 1 ? "n" : "");
    } else if (minutes > 0) {
      return "vor " + minutes + " Minute" + (minutes > 1 ? "n" : "");
    } else {
      return "vor " + seconds + " Sekunde" + (seconds > 1 ? "n" : "");
    }
  }

  async function fetchActivities() {
    setActivities(null)
    document.getElementsByClassName("loaderDivFetch")[0].style.display = "block";
    setTimeout(() => {
      getDocs(query(
        collection(firestore, "activities"),
        where("timestamp", ">", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), //last 7 days
        where("organizer", "array-contains", organizerId),
        where("type", "in", ["bookmark", "attending", "purchase"]),
        orderBy("timestamp", "desc"),
      )).then(async (data) => {
        const promises = data.docs.filter(e => !e.get('hidden')).map(async document => {
          //get the username
          const usernameRaw = (await getDoc(doc(collection(firestore, 'profiles'), document.data().user ?? '-'))).data()?.username ?? 'Ein Gast';
          const username = usernameRaw.charAt(0).toUpperCase() + usernameRaw.slice(1)
          //get the event
          const eventname = (await getDoc(doc(collection(firestore, 'events'), document.data().event ?? '-'))).data()?.name ?? 'NOT_FOUND';
          //generate the description
          let description;
          let icon;
          switch (document.data().type) {
            case 'bookmark': description = `hat sich "${eventname}" gemerkt`; icon = bookmarksIcon; break;
            case 'attending': description = `nimmt an "${eventname}" teil`; icon = followersIcon; break;
            case 'purchase': description = `hat Tickets für "${eventname}" erworben`; icon = ticketIcon; break;
            default: description = 'default'; break;
          }

          return {
            username,
            description,
            icon,
            ...document.data(),
          };
        });
        const activities = await Promise.all(promises);
        setActivities(activities);
        if (document.getElementsByClassName("loaderDivFetch").length > 0)
          document.getElementsByClassName("loaderDivFetch")[0].style.display = "none";
      })
    }, 1000)
  }
  const [nextEvent, setNextEvent] = useState(null);
  const [noUpcoming, setNoUpcoming] = useState(false)
  const [noTicketing,setNoTicketing] = useState(true)


  useEffect(() => {
    getDocs(
      query(
        collection(firestore, "events"),
        where("start", ">", new Date()),
        where("visibility", "in", ["private", "public"]),
        where("organizer", "array-contains", organizerId)
      )
    ).then((data) => {
      if (data.docs.length > 0) {
        setNextEvent(data.docs[0])
        if (["standard", "hidden"].includes(data.docs[0].data().ticket)) {
          setNoTicketing(false)
        }
      }
      else {
        setNoUpcoming(true)
      }
    });
  }, [organizerId]);

  useEffect(() => {
    if (nextEvent != null && nextEvent.data().venue) {
      getDoc(
        doc(collection(firestore, "organizers"), nextEvent?.data().venue ?? "-")
      )
        .then((snapshot) => {
        })
        .catch((error) => console.log(error));
    }
    else if (nextEvent != null && nextEvent.data().length === 0) {
    }
  })


  var d = new Date();
  var m = new Date();
  var day = d.getDay().toString();
  var month = m.getMonth().toString();
  if (day.length === 1) {
    day = "0" + day;
  }
  if (month.length === 1) {
    month = "0" + month;
  }



  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    Filler
  )

  return (
    <>
      <div className="content" style={{ overflowY: "hidden" }}>
        <div id="breadcrumb-title-text" onClick={() => navigate(-1)}>Home</div>
        <div className="not-all-inputs-done" id="warningInput"style={paymentsDoc?.data().payment_account?.status === 'active' || paymentsDoc?.data().payment_account?.status == null ? { display: "none" } : { visibility: "visible" }} onClick={() => {
          searchParams.set('show_modal', 'settings');
          searchParams.set('settings_page', 'ticketing');
          setSearchParams(searchParams);
        }}>
          <div>{warningIcon}</div>
          {paymentsDoc?.data().payment_account?.status === 'update_required' && <div id="notallInpText">
            Achtung: Um Ticketing erfolgreich zu aktivieren musst du noch fehlende Angaben nachreichen. Gehe in die Einstellungen oder klicke hier um diese zu vervollständigen.
          </div>}
          {paymentsDoc?.data().payment_account?.status === 'inactive' && <div id="notallInpText">
            Achtung: ELGIO Ticketing wurde für dein Konto temporär deaktiviert. Klicke hier um deine Daten zu vervollständigen und somit ELGIO Ticketing wieder zu aktivieren.
          </div>}
          {!paymentsDoc?.data().payment_account?.status && <div id="notallInpText">
            Achtung: Um ELGIO Ticketing zu aktivieren, fehlen uns noch ein paar Angaben von dir.
          </div>}
        </div>
        <div className="stats-box-dashboard">
          <div id="ticketkaeufer" className="stats">
            <div className="stats-icon" style={{ backgroundColor: "var(--bgOrange)" }}>{viewsIcon}</div>
            <div>
              <div className="stats-title">Eventaufrufe</div>
              <div className="stats-counter animate__animated animate__slideInDown">{ticketSales}</div>
              <div className="stats-subtitle">in den letzten 30 Tagen</div>
            </div>
          </div>
          <div id="aufrufe" className="stats">
            <div className="stats-icon" style={{ backgroundColor: "var(--bgBlue)" }}>{viewsIcon}</div>
            <div className="stats-home-box">
              <div className="stats-title">Profilaufrufe</div>
              <div className="stats-counter animate__animated animate__slideInDown">{viewsOrga}</div>
              <div className="stats-subtitle">in den letzten 30 Tagen</div>
            </div>
          </div>
          <div id="follower" className="stats">
            <div className="stats-icon" style={{ backgroundColor: "var(--bgGreen)" }}>{followersIcon}</div>
            <div>
              <div className="stats-title">Followeranzahl</div>
              <div className="stats-counter animate__animated animate__slideInDown">{followers}</div>
              <div className="stats-subtitle">insgesamt</div>
            </div>
          </div>
          <div id="teilungen" className="stats">
            <div className="stats-icon" style={{ backgroundColor: "var(--purple)" }}>{sharesIcon}</div>
            <div>
              <div className="stats-title">Eventteilungen</div>
              <div className="stats-counter animate__animated animate__slideInDown">{sharesOrga}</div>
              <div className="stats-subtitle">in den letzten 30 Tagen</div>
            </div>
          </div>
        </div>
        <div className="DashBottomBox">
          <div className="DashBottomBoxLeft">
            <div className="nextEventGridBox">
              <LoaderDivWithOverlay></LoaderDivWithOverlay>
              <div className="dashboardBoxTitle">Nächstes Event</div>
              <div className="nextEventBox" style={noUpcoming ? {} : { cursor: "pointer" }}>
                <div className="placeholderNextEvent" style={noUpcoming ? {display: "flex"} : {display: "none"}}>
                  <div
                    id="eventDetailsText"
                    className={noUpcoming ? "animate__animated animate__fadeInDown" : ""}
                    >
                    {noUpcoming ? "Du hast keine anstehenden Events" : ""}
                  </div>
                  <Link to={"/dashboard/events/event-erstellen"} className={noUpcoming ? "Item animate__animated animate__fadeInDown" : ""}>
                    <div id="newEventButton">
                      {plusIcon}
                      <div className="buttonText">Neues Event</div>
                    </div>
                  </Link>
                </div>

                {nextEvent && <EventBox
                  id={nextEvent.id}
                  key={nextEvent.id}
                  startDateRaw={nextEvent.data()?.start.toDate()}
                  canceled={nextEvent.data()?.canceled ?? false}
                  date={nextEvent.data()?.start?.toDate() && (nextEvent.data().start.toDate().toLocaleDateString("de-DE", { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ab ' +
                    nextEvent.data().start.toDate().toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit' })
                    + ' Uhr')}
                  titel={nextEvent.data().name}
                  club={nextEvent.data().venue}
                  address={nextEvent.data().address}
                  shares={nextEvent.data().attendee_count ?? 0}
                  views={nextEvent.data().view_count ?? 0}
                  saved={nextEvent.data().bookmark_count ?? 0}
                  visibility={nextEvent.data().visibility}
                  banner={
                    (nextEvent.data().images?.length ?? 0) > 0
                      ? nextEvent.data().images[0]
                      : null
                  }
                  showStats
                  noTicketing={noTicketing}
                />}
              </div>
            </div>
            <div className="Uebersicht">
              <div className="statistikBox" id="Statistik">
                <div className="box-title-flex">
                  <div className="dashboardBoxTitle">Statistiken</div>
                  <div className="col-flex flex-gap-1rem" style={{ textAlign: "center" }}>
                    <div style={{ color: "var(--bg5)" }}>Letzte 30 Tage</div>
                  </div>
                </div>
                <div className="row-flex" style={{ height: "100%", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
                  <div className="toggleThrough" onClick={
                    () => {
                      if (statisticsMenuState === 0) {
                        setStatisticsMenuState(2)
                      }
                      else {
                        setStatisticsMenuState(statisticsMenuState - 1)
                      }
                    }}
                  >
                    {chevronLeftIcon}
                  </div>
                  <div className="row-flex justify_center align_center" style={{ height: "100%", width: "100%" }}>
                    {(() => {
                      switch (statisticsMenuState) {
                        case 0: return <KundenstammTab customerStatusesSum={customerStatusesSum} customerStatusesData={customerStatuses} customerStatuses={customerStatuses}></KundenstammTab>;
                        case 1: return <GeschlechtTab customerGendersSum={customerGendersSum} customerGendersData={customerGenders} customerGenders={customerGenders}></GeschlechtTab>;
                        case 2: return <AlterTab customerAgesSum={customerAgesSum} customerAgesData={customerAges} customerAges={customerAges}></AlterTab>;
                        default: return <KundenstammTab customerStatusesSum={customerStatusesSum} customerStatusesData={customerStatuses} customerStatuses={customerStatuses}></KundenstammTab>;
                      }
                    })()}
                  </div>
                  <div className="row-flex justify_center align_center">
                    <div className="toggleThrough" onClick={
                      () => {
                        if (statisticsMenuState === 2) {
                          setStatisticsMenuState(0)
                        }
                        else {
                          setStatisticsMenuState(statisticsMenuState + 1)
                        }
                      }}
                    >
                      {chevronRightIcon}
                    </div>
                  </div>
                </div>
              </div>
              <div className="TicketBox" id="Tickets">
                <div className="box-title-flex">
                  <div className="box-title-first-ele">
                    <div className="dashboardBoxTitle">Ticketverkäufe</div>
                  </div>
                  <div className="box-title-second-ele" style={{ justifyContent: "center", alignItems: "center", gap: "1rem" }}>
                    <div style={{ color: "var(--textMain)", cursor: "pointer" }}
                      onClick={() => {
                        if (ticketSalesState === 0) { setTicketSalesState(3) }
                        else {
                          setTicketSalesState(ticketSalesState - 1)
                        }
                      }}>{chevronLeftIcon}</div>
                    <div className="uebersichtTitelDetails" style={{ textAlign: "center", width: "50%"}}>{ticketSalesActiveText}</div>
                    <div style={{ color: "var(--textMain)", cursor: "pointer" }} onClick={() => {
                      if (ticketSalesState === 3) { setTicketSalesState(0) }
                      else {
                        setTicketSalesState(ticketSalesState + 1)
                      }
                    }}>{chevronRightIcon}</div>
                  </div>
                </div>
                <div>
                  <div className="enormous t-main font-semibold mb-10p">
                    {isNaN(ticketSalesActiveNum) ? "0,00 €" : (ticketSalesActiveNum.toFixed(2)).replace(".", ",") + " €"}
                  </div>
                  <div className="chart-box">
                    <LineGraph data={ticketSalesActiveSums} labels={ticketSalesActiveLabels} colors={['#fdfd44']} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="DashBottomBoxRight">
            <div className="AktivitaetBoxInGrid">
              <div className="AktivitaetBox">
                <div className="box-title-flex">
                  <div className="box-title-first-ele">
                    <div className="dashboardBoxTitle">Aktivitäten</div>
                  </div>
                  <div className="box-title-second-ele">
                    <div className="uebersichtTitelDetails" style={{ cursor: "pointer"}} onClick={fetchActivities}>Neu Laden</div>
                  </div>
                </div>
                <div className="activity-box-body-flex" style={activities?.length === 0 ? { justifyContent: 'center', alignItems: "center", height: "100%" } : {}}>
                  <LoaderFetch></LoaderFetch>
                  {activities === null ? <></> : activities.length === 0
                    ? <div id="noUpcoming" style={{ color: "var(--textSecondary)", textAlign: "center" }}>Noch keine Aktivitäten vorhanden!</div>
                    : activities.map((activity, index) => {
                      return (
                        <div key={index} className="activity">
                          <div className="activityLeft">
                            <div className="circle-flex">
                              <div className="circleNormal" id="circle-dashboard">
                                <div className="activityVal">{activity.icon}</div>
                              </div>
                            </div>
                            <div className="activityText">
                              <div className="coupon-code-name">{activity.username}</div>
                              <div className="coupon-code-subname">{activity.description}</div>
                            </div>
                          </div>
                          <div className="activityRight">
                            <div className="activity-timestamp">{timeAgo(activity.timestamp.toDate())}</div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalChangelog show={showLog} handleClose={handleCloseLog} />
    </>
  );
}

function KundenstammTab(props) {

  //Use setTimeframe to set timeframe for all statistics
  // eslint-disable-next-line no-unused-vars
  const [timeframe, setTimeframe] = useState('last_30_days');
  const { getCustomerStatuses } = useContext(StatsContext);

  //Kundenstamm
  const [customerStatuses, customerStatusesLabels] = getCustomerStatuses({ timeframe });

  return (
    <>
      <div className="StatisticItemBoxMini">
        <div className="BoxMiniHeader">
          <div className="BigStatisticHeader">Kundenstamm</div>
          <FurtherInfo
            explanation="Hier siehst du die Verteilung deiner Kunden in Altersgruppen. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
          />
        </div>
        <PieChart data={customerStatuses} labels={customerStatusesLabels} colors={['green', 'yellow']} />
      </div>
    </>
  )
}

function GeschlechtTab(props) {

  //Use setTimeframe to set timeframe for all statistics  
  // eslint-disable-next-line no-unused-vars
  const [timeframe, setTimeframe] = useState('last_30_days');
  const { getCustomerGenders } = useContext(StatsContext);

  //Genders
  const [customerGenders, customerGendersLabels] = getCustomerGenders({ timeframe })

  return (
    <>
      <div className="StatisticItemBoxMini">
        <div className="BoxMiniHeader">
          <div className="BigStatisticHeader">Geschlecht</div>
          <FurtherInfo
            explanation="Hier siehst du die Verteilung deiner Kunden in Geschlechtern. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
          />
        </div>
        <PieChart data={customerGenders} labels={customerGendersLabels} colors={['green', 'yellow', 'blue']} />
      </div>
    </>
  )
}

function AlterTab(props) {

  //Use setTimeframe to set timeframe for all statistics
  // eslint-disable-next-line no-unused-vars
  const [timeframe, setTimeframe] = useState('last_30_days');
  const { getCustomerAges } = useContext(StatsContext);

  //Ages
  const [customerAges, customerAgesLabels] = getCustomerAges({ timeframe });

  return (
    <>
      <div className="StatisticItemBoxMini">
        <div className="BoxMiniHeader">
          <div className="BigStatisticHeader">Alter</div>
          <FurtherInfo
            explanation="Hier siehst du die Verteilung deiner Kunden in Altersgruppen. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
          />
        </div>
        <BarChart data={customerAges} labels={customerAgesLabels} colors={['green']} />
      </div>
    </>
  )
}

export default Home;